import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { Activity } from 'state-domains/domain/project';
import { buildConfigurationProjectActivityUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteConfigurationProjectActivity = (
    projectId: string,
    activityId: string,
): Observable<Activity> => {
    const obs = sendDeleteRequestWithXSRFToken(
        buildConfigurationProjectActivityUrl(projectId, activityId),
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<Activity>) => {
            const res = convertToCamel<Activity>(response);
            return observableOf(res);
        }),
    );
};
